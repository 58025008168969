<template lang="pug">
form-panel(:title="title")
    template(v-slot:headerActions)
        v-btn(small outlined color="success" @click="addContact")
            v-icon(left) add
            | {{ $t('actions.add') }}
    v-row.pt-2(v-for="(contact, index) in model" :key="`${name}-${index}`")
        v-col(cols="12" sm="4" class="py-0")
            text-input(
                v-model="contact.name"
                v-validate.immediate="'space'"
                label="labels.name"
                :name="`${index}-name`"
                :data-vv-scope="name"
                :data-vv-as="$t('labels.name')"
                :error-messages="errors.collect(`${index}-name`, name)"
            )
        v-col(cols="12" sm="4" class="py-0")
            text-input(
                v-model="contact.email"
                v-validate.immediate="'space'"
                label="labels.email"
                :name="`${index}-email`"
                :data-vv-scope="name"
                :data-vv-as="$t('labels.email')"
                :error-messages="errors.collect(`${index}-email`, name)"
            )
        v-col(cols="10" sm="3" class="py-0")
            text-input(
                v-model="contact.phone"
                v-validate.immediate="'phonenumber'"
                label="labels.phone"
                :name="`${index}-phone`"
                :data-vv-scope="name"
                :data-vv-as="$t('labels.phone')"
                :error-messages="errors.collect(`${index}-phone`, name)"
                @blur="contact.phone = formatPhoneNumber(contact.phone)"
            )
        v-col.mt-3(v-if="index > 0" cols="2" sm="1" class="py-0")
            v-btn(icon color="error" @click="removeContact(index)")
                v-icon delete
    slot
</template>

<script>
import FormInput from '@/components/mixins/FormInput'
import PhoneNumberMixin from '@/components/mixins/PhoneNumberMixin'
import FormPanel from '@/components/form/FormPanel'
import TextInput from '@/components/form/Inputs/TextInput'

export default {
    components: {
        FormPanel,
        TextInput,
    },
    inject: {
        $validator: 'formValidator',
    },
    mixins: [FormInput, PhoneNumberMixin],
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: '',
        },
        errorMessages: {
            type: [Array, Object],
            default: () => [],
        },
        name: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            model: [],
        }
    },
    methods: {
        addContact() {
            this.model.push({
                name: '',
                email: '',
                phone: '',
            })
        },
        removeContact(index) {
            this.model.splice(index, 1)
        },
    },
}
</script>
