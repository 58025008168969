export default {
    computed: {
        headers() {
            return [
                {
                    value: 'lastName',
                    text: this.$t('labels.name'),
                    sortable: true,
                    type: 'string',
                },
                {
                    value: 'email',
                    text: this.$t('labels.email'),
                    sortable: true,
                    type: 'string',
                },
                {
                    value: 'phone',
                    text: this.$t('labels.phone'),
                    sortable: true,
                    class: 'd-none d-lg-table-cell',
                    type: 'string',
                },
            ]
        },
    },
}
