export default {
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            model: {},
        }
    },

    watch: {
        model: {
            handler() {
                this.$emit('input', this.model)
            },
            immediate: true,
            deep: true,
        },

        value: {
            handler(val) {
                this.$set(this, 'model', val)
            },
            immediate: true,
        },
    },
}
