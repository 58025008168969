<template>
    <div>
        <company-form :entity-id="companyId"></company-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import authType from '@/store/type/authType'
import CompanyForm from '@/components/pages/companies/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: { CompanyForm },
    mixins: [dirtyForm],

    computed: {
        ...mapGetters({
            companyId: authType.getters.ACTIVE_COMPANY_ID,
        }),
    },
}
</script>
