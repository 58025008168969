<template>
    <v-btn color="success" @click="addRelation">
        <v-icon left>add</v-icon> {{ $t('actions.add') }}
    </v-btn>
</template>

<script>
export default {
    data: () => ({
        relationModel: {
            '@type': 'CCPartnerRelation',
            deliveryFee: '0.00',
            deliveryTime: 0,
            restaurant: null,
        },
    }),

    methods: {
        addRelation() {
            const id = new Date().getTime()
            const relation = { ...this.relationModel, '@id': id }
            this.$emit('added', relation)
        },
    },
}
</script>
