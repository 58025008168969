<template>
    <div>
        <company-form :entity-id="companyId"></company-form>
    </div>
</template>

<script>
import CompanyForm from '@/components/pages/companies/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: { CompanyForm },
    mixins: [dirtyForm],

    props: {
        companyId: {
            type: [String, Number],
            default: null,
        },
    },
}
</script>
